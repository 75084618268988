<template>
  <div class="Post">
    <v-container fluid class="py-0">
      <v-row justify="center" class="mt-12">
        <v-col cols="11" sm="10" lg="8" xl="7">
          <!-- ****************inicia título**************** -->
          <v-row no-gutters justify="center">
            <v-col class="text-center" cols="auto">
              <div class="Post__div-title">
                <span
                  class="titles-extra-big-xx bree-serif line-height-some-titles"
                >
                  {{ newSingle.title }}
                </span>
              </div>
            </v-col>
          </v-row>
          <!-- ****************/termina título**************** -->

          <!-- ****************inicia contenido**************** -->
          <v-row v-for="(row, r) in newSingle.content" v-bind:key="r">
            <v-col
              v-for="(col, c) in row.value"
              v-bind:key="c"
              v-bind:md="12 / row.value.length"
              cols="12"
              class=""
            >
              <template v-for="(children, ch) in col.children">
                <div :key="ch">
                  <template v-if="children.component == 'text'">
                    <div v-html="complete(children.settings.text)"></div>
                  </template>

                  <template v-else-if="children.component == 'html'">
                    <template v-if="children.settings.html != null">
                      asd
                      <div
                        class="iframe-div"
                        v-html="complete(children.settings.html)"
                      ></div>
                    </template>
                  </template>

                  <template v-else-if="children.component == 'image'">
                    <template v-if="children.settings.image != null">
                      <template v-if="isVideo(children.settings.image.path)">
                        <video width="100%" controls="false" placeInLine muted>
                          <source
                          :controls="false"
                            type="video/mp4"
                            :src="
                              'https://www.cymcms.actstudio.xyz/' +
                              children.settings.image.path
                            "
                          />
                        </video>
                      </template>
                      <template v-else>
                        <v-img
                          :src="
                            'https://www.cymcms.actstudio.xyz/' +
                            children.settings.image.path
                          "
                        ></v-img>
                      </template>
                    </template>
                  </template>
                </div>
              </template>
            </v-col>
          </v-row>
          <!-- ****************/termina contenido**************** -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newSingle: {},
    };
  },
  watch: {
    $route(to, from) {
      //fetch para traer noticia
      fetch(
        "https://www.cymcms.actstudio.xyz/api/collections/get/news?token=aa5f1f62f706c89e9dae0ea5e2400a",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: {
              url: this.$route.params.new,
            },
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.newSingle = res.entries[0]))
        .catch((error) => console.error(error));
    },
  },
  beforeCreate() {
    //fetch para traer noticia
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/news?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            url: this.$route.params.post,
          },
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.newSingle = res.entries[0]))
      .catch((error) => console.error(error));
  },
  mounted() {
    //emite el valor para cambiar el valor del logo
    this.$emit("update", 0);
  },
  methods: {
    isVideo: function (path) {
      return path.substring(path.length - 4) == ".mp4";
    },
    complete: function (html) {
      var text = html.replace(
        '<img src="/',
        '<img src="https://www.cymcms.actstudio.xyz/'
      );

      return text;
    },
  },
};
</script>